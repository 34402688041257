<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories" :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                    <label>Nhóm địa điểm</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.categoryName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.categoryId.required">Vui lòng chọn nhóm vật tư</span>
                                </md-autocomplete>
                                <md-button @click="openCategory()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <md-field :class="{'md-invalid': submitted && $v.entity.locationCode.$error }">
                                <label for="locationCode">Mã địa điểm</label>
                                <md-input name="locationCode" v-model="entity.locationCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.locationCode.required">Vui lòng nhập địa điểm</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.locationAlias.$error }">
                                <label for="locationAlias">Tên viết tắt</label>
                                <md-input name="locationAlias" v-model="entity.locationAlias"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.locationAlias.required">Vui lòng nhập tên viết tắt</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.locationName.$error }">
                                <label for="locationName">Tên địa điểm</label>
                                <md-input name="locationName" v-model="entity.locationName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.locationName.required">Vui lòng nhập tên địa điểm</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field>
                                <label for="address">Địa chỉ</label>
                                <md-input name="address" v-model="entity.address" ></md-input>
                            </md-field>

                            <md-field>
                                <label for="long">Kinh độ</label>
                                <md-input name="long" v-model="entity.long" ></md-input>
                            </md-field>
                            <md-field>
                                <label for="lat">Vĩ độ</label>
                                <md-input name="lat" v-model="entity.lat" ></md-input>
                            </md-field>
                            
                            <md-field>
                                <label for="note">Ghi chú</label>
                                <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <categoryList ref="categoryList" :type="4" title="nhóm địa điểm" @close="closeCategory"/>
    </md-dialog>
</template>
<script>
    import locationService from '../../../api/locationService';
    import categoryService from '../../../api/categoryService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import categoryList from '../../../components/popup/_CategoryList.vue';

    export default ({
        components: {
            categoryList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật địa điểm'
        },
        data() {
            return {
                showDialog: false,
                saveAndCreate: false,
                title: '',
                id: 0,
                submitted: false,
                categories: [],
                categoryName: '',
                entity: { id: 0, categoryId: 0, locationCode: '', locationAlias: '', locationName: '', address: '', note: '', lat: '', long: '', isDeleted: false, isActive: true },
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                await this.getCategories();
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới địa điểm';
                    this.typeName = '';
                    this.entity = { id: 0, categoryId: 0, locationCode: '', locationAlias: '', locationName: '', address: '', note: '', lat: '', long: '', isDeleted: false, isActive: true };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật địa điểm';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
                this.setLoading(false);
            },

            close(){
                this.showDialog = false;
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryName;
                this.entity.categoryId = item.id;
                this.$refs.categoryList.close();
            },

            getCategorySelected(val){
                this.entity.categoryId = val.id;
                this.categoryName = val.categoryName;
            },

            openCategory(){
                this.$refs.categoryList.open(4);
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 4, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
                this.$emit('close', this.saveAndCreate);
            },

            add(){
                this.setLoading(true);
                locationService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                locationService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                locationService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.categoryName = response.data.category.categoryName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: { 
            categoryName: function (val) { 
                if(val == ''){
                    this.entity.categoryId = 0;
                }
            }
        },
        validations: {
            entity: {
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                locationCode: { required },
                locationAlias: { required },
                locationName: { required }
            }
        }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 800px;
        height: 430px;
        max-width: 800px;
    }
    .md-scrollbar {
        height: 340px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>